import { useMutation, useQueryClient, useQuery } from '@tanstack/vue-query';
import { regulationsService } from '~/utils/api/regulationsService';

export function useRegulations() {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { data, isFetching, error } = useQuery({
    queryKey: ['regulations'],
    queryFn: regulationsService.getRegulations,
  });

  const { mutate: acceptRegulations } = useMutation({
    mutationFn: () => regulationsService.acceptRegulations(),
    onSuccess: () => {
      toast.add({
        severity: 'success',
        detail: 'Regulaminy zostały zaakceptowane',
        life: 3000,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas akceptowania regulaminów',
        life: 3000,
      });
    },
  });

  return { data, error, isFetching, acceptRegulations };
}
