import { fetcher } from '../fetcher';

export const regulationsService = {
  acceptRegulations: async () => {
    return await fetcher('/regulations/accept', {
      method: 'POST',
    });
  },
  getRegulations: async () => {
    return await fetcher('/regulations', {
      method: 'GET',
      schema: regulationsSchema,
    });
  },
};
